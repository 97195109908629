var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'customer',
    'inscription',
    'check_in_date_time',
    'check_out_date_time',
    'activity',
    'instructor',
    'note' ],"formats":{
    check_in_date_time: 'DateTime',
    check_out_date_time: 'DateTime',
  },"itemsPerPage":"10","searchOnStart":true,"table":"attendances"}})}
var staticRenderFns = []

export { render, staticRenderFns }